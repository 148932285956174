import React, { useState, useEffect } from "react";
import styles from './Popup.module.scss'
import { getCookie, setCookies, deleteCookie } from "cookies-next";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import CloseIcon from "../../public/assets/icons/closeDark.svg";

const Popup = ({ data }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    let sp = true;
    if (getCookie("popup")) {
      let closedVal = JSON.parse(getCookie("popup")).closed;
      if (closedVal == data.title)
        sp = false;
      else {
        if(deleteCookie) deleteCookie("popup");
      }
    }

    setShowPopup(sp);
  }, []);

  const closeShort = () => {
    setShowPopup(false);
    setCookies(
      "popup",
      {
        closed: data.title
      }
    );
  };
  const closeLong = () => {
    setShowPopup(false);
    setCookies(
      "popup",
      {
        closed: data.title
      },
      {
        maxAge: 2592000,
      }
    );
  };

  return (
    <>{showPopup && <div className={styles.popup}>
      <div className={styles.PopupContainer}>
        <a className={styles.shortClose} onClick={closeShort}><CloseIcon /></a>
        {data.image && <div className={styles.topImage}>
          <img src={data.image} />
          {<img className={styles.mobile} src={data.image_mobile} />}
        </div>}
        <div className={styles.content}>
          {data.title && <div className={styles.title} dangerouslySetInnerHTML={{__html: data.title}}></div>}
          <div className={styles.text} dangerouslySetInnerHTML={{__html: data.text}}>
          </div>
          <div className={styles.buttons}>
            {data.link && <div><ButtonWithIcon link={data.link} onClick={closeShort} outline={false}  /></div>}
            <div><a className={styles.longClose} onClick={closeLong}>{data.close_text}</a></div>
          </div>
        </div>
      </div>
    </div>}</>
  );
}

export default Popup;